// assets
import HeroImage from "../../assets/images/heroes/privacy.avif";

export const meta = {
  title: "Privacy Policy",
  description: "",
  keywords: [""],
};

export const hero = {
  image: {
    src: HeroImage,
    alt: "",
    width: 1000,
    height: 1000,
  },
  contents: [
    "<h2>Your Privacy Choices</h2>",
    "At Ninacci, we empower you to make informed choices about collecting and using your personal information. Your privacy is a priority, and we are committed to protecting it.",
    "<h2>Our Commitment To Privacy</h2>",
    "Your trust is important to us. We've outlined our practices in this Privacy Policy to ensure your privacy is respected. This notice is available on our homepage and at every point where personal information may be requested. We guarantee not to sell, rent, exchange, or distribute your personal information to any outside parties.",
    "<h2>Information We Collect</h2>",
    "This Privacy Policy applies to all information collected or submitted through the www.ninacci.com website. Personal information we collect may include:",
    [
      "First Name",
      "Last Name",
      "Email Address",
      "Phone Number",
      "Additional Details (if applicable)",
    ],
    "<h2>How We Use Information</h2>",
    "We use the information you provide when requesting an online appointment to fulfill your request and ensure a smooth experience. Your information is not shared with outside parties except when necessary to complete your appointment. We may use your email address to respond to your inquiries, but your email will never be used for other purposes or shared with third parties without your consent.",
    "We deeply respect your privacy and will never use or share your personal information in ways unrelated to those described here without allowing you to opt-out or prohibit such uses.",
    "<h2>Data Security</h2>",
    "We have implemented robust physical, electronic, and managerial safeguards to protect your information from unauthorized access, ensure its accuracy, and maintain its proper use. Your data is protected by industry-standard security measures to ensure your privacy is always upheld.",
    "<h2>Contact Us</h2>",
    "If you have any questions or concerns about this Privacy Policy, please email us at <a href='mailto:sales@ninacci.com'>sales@ninacci.com</a>. We are committed to addressing your concerns.",
  ],
};
