// packages
import axios from "axios";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

// assets
import BannerCatalog from "../../assets/images/banners/catalog.avif";

// classes
import Diamond from "../../shared/classes/Diamond";
import Jewelry from "../../shared/classes/Jewelry";

// components (shared)
import ItemGrid from "../../shared/components/ItemGrid";

// components (template)
import BigSlider from "../../components/BigSlider";
import Categories from "../../components/Categories";
import Features from "../../components/Features";
import Header from "../../components/Header";
import ImageBanner from "../../components/ImageBanner";
import Promos from "../../components/Promos";
import SliderWithText from "../../components/SliderWithText";
import StandardContainer from "../../components/StandardContainer";

// data
import { categories } from "../../data/categories";
import { data as jewelrySliderData } from "../../data/components/jewelrySlider";
import { descriptions, features } from "../../data/pages/home";

export default function HomeB() {
  const [featuredItems, setFeaturedItems] = useState([]);

  useEffect(() => {
    const fetchFeaturedItems = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/companies/${process.env.REACT_APP_COMPANY_ID}/featured-items.json`,
          {
            headers: {
              Authorization: localStorage.token,
            },
          },
        );
        const rawData = response.data;
        let data = {};
        data.diamonds = rawData.diamonds.map(
          (item) => new Diamond(item, "/diamonds"),
        );
        data.jewelry = rawData.jewelry.map(
          (item) => new Jewelry(item, "/jewelry"),
        );
        setFeaturedItems(data);
      } catch (error) {
        console.error("Error fetching featured items:", error);
      }
    };

    fetchFeaturedItems();
  }, []);

  const diamondSection = (
    <StandardContainer
      content={
        <div>
          <NavLink
            className="text-t-gray"
            exact="exact"
            to="/diamonds"
          >
            <Header
              title="Diamonds"
              topBorder={true}
              bottomBorder={false}
            />
          </NavLink>
          <div className="flex flex-col gap-4 md:gap-8">
            <p className="s-h4 mx-auto max-w-screen-xl text-center">
              {descriptions.diamonds}
            </p>
            <Categories
              data={categories.diamonds.categories}
              imageCssClasses={categories.diamonds.imageCssClasses}
              path={categories.diamonds.path}
              param={categories.diamonds.param}
              // title={categories.diamonds.title}
            />
            <ItemGrid
              data={featuredItems["diamonds"]}
              limit={8}
            />
          </div>
        </div>
      }
    />
  );

  const jewelrySection = (
    <div>
      <StandardContainer
        content={
          <div>
            <NavLink
              className="text-t-gray"
              exact="exact"
              to="/jewelry"
            >
              <Header
                title="Jewelry"
                topBorder={true}
                bottomBorder={false}
              />
            </NavLink>
            <p className="s-h4 mx-auto max-w-screen-xl text-center">
              {descriptions.jewelry}
            </p>
            <div className="mt-4 md:mt-6">
              {jewelrySliderData.map((item, idx) => (
                <SliderWithText
                  key={idx}
                  {...item}
                  reverse={idx % 2 === 1}
                />
              ))}
            </div>
          </div>
        }
      />
    </div>
  );

  const catalogSection = (
    <StandardContainer
      content={
        <>
          <NavLink
            className="text-t-gray"
            exact="exact"
            to="/catalog"
          >
            <Header
              title="Catalog"
              topBorder={true}
              bottomBorder={false}
            />
            <ImageBanner image={BannerCatalog} />
          </NavLink>
        </>
      }
    />
  );

  return (
    <div className="flex flex-col gap-4 md:gap-8">
      <BigSlider />
      <Features data={features} />
      {diamondSection}
      {jewelrySection}
      {catalogSection}
      <Promos />
    </div>
  );
}
