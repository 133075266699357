// packages
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// assets
import "swiper/css";

// components
import StandardContainer from "./StandardContainer";

// data
import { bigSlider } from "../data/components/bigSlider";

export default function BigSlider() {
  const content = (
    <div>
      <Swiper
        modules={[Autoplay, Pagination]}
        autoplay={{
          delay: 2500,
        }}
        loop={true}
        slidesPerView={1}
        spaceBetween={0}
        speed={1500}
        pagination={{
          enabled: true,
          clickable: true,
          dynamicBullets: true,
        }}
        style={{
          "--swiper-pagination-color": "#404040",
          "--swiper-pagination-bullet-inactive-color": "#c6a58e",
          "--swiper-pagination-bullet-size": "1rem",
          "--swiper-pagination-bullet-border-radius": "0",
        }}
      >
        {bigSlider.map((item, idx) => (
          <SwiperSlide key={idx}>
            <img
              className="size-full object-contain"
              src={item.image.src}
              alt=""
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );

  return <StandardContainer content={content} />;
}
