// data
import { meta } from "../data/meta";

export default function IntroComponent() {
  return (
    <div className="fixed inset-0 flex h-screen w-full items-center justify-center bg-white px-4">
      <div className="flex flex-col items-center justify-center space-y-4">
        <h2 className="animate-slideUp shine-effect relative text-center font-logo text-5xl uppercase tracking-wide text-t-beige sm:text-7xl">
          {meta.title}
        </h2>
        <p className="s-text animate-slideUpDelay text-center text-t-beige">
          {meta.slogan}
        </p>
      </div>
    </div>
  );
}
