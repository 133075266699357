// assets
import HeroImage1 from "../../assets/images/heroes/about1.avif";
import HeroImage2 from "../../assets/images/heroes/about2.avif";

export const meta = {
  title: "About",
  description: "",
  keywords: [""],
};

export const hero = {
  image: {
    src: HeroImage1,
    alt: "",
    width: 1000,
    height: 1000,
  },
  contents: [
    "We are preeminent designers and manufacturers of fine diamond jewelry renowned for crafting timeless pieces that epitomize elegance and sophistication at the forefront of luxury.",
    "As an importer and distributor of the world's finest loose diamonds, each stone in our collection is certified by GIA and EGL, ensuring the highest standards of quality and authenticity.",
    "With a commitment to exceptional craftsmanship and unparalleled service, we combine artistry and precision to create breathtaking jewelry that reflects our client's unique style and vision.",
  ],
};

export const body = {
  image: {
    src: HeroImage2,
    alt: "",
    width: 1000,
    height: 1000,
  },
  contents: [
    "We invite you to experience Ninacci's unwavering passion for exquisite design and masterful craftsmanship in fine diamond jewelry.",
    "Life's most important moments deserve to be celebrated with jewelry that transcends time and embodies emotion. Each piece in our collection is a true masterpiece, meticulously crafted with exceptional attention to detail, ensuring every creation perfectly blends beauty and precision.",
    "Our dedication to refinement is reflected in our sophisticated, elegant designs, featuring only the finest, ideal-cut certified diamonds. As a designer, manufacturer, and diamond cutter, we have the unique ability to work with a variety of diamonds and create bespoke pieces tailored to your preferences. With our in-house design team, we are committed to transforming your vision into a stunning reality, delivering unmatched quality, value, and artistry in every creation.",
    "Ninacci's exceptional designs are available at upscale retailers across the United States and internationally, bringing the pinnacle of luxury jewelry to discerning clients worldwide.",
  ],
};
