// components
import StandardContainer from "./StandardContainer";

export default function Features({ data }) {
  const content = (
    <div className="flex flex-col gap-4 sm:gap-8">
      {data.map((item, idx) => (
        <div
          id={idx}
          className="grid grid-cols-1 items-center gap-4 sm:gap-8 md:grid-cols-2"
        >
          <p
            key={idx}
            className={`${idx % 2 === 1 ? "order-first" : "order-last"} s-text text-center`}
          >
            {item.content}
          </p>
          <img
            className="h-full w-auto object-cover"
            src={item.image.src}
            alt={item.image.alt}
            // width={item.image.width}
            // height={item.image.height}
          />
        </div>
      ))}
    </div>
  );

  return <StandardContainer content={content} />;
}
