// components (template)
import Hero from "../../components/Hero";

// data
import { body, hero, meta } from "../../data/pages/about";

export default function About() {
  return (
    <>
      <Hero
        title={meta.title}
        image={hero.image}
        content={hero.contents}
      />
      <Hero
        image={body.image}
        content={body.contents}
        flip={true}
        showBorders={false}
      />
    </>
  );
}
