// assets
import Image01 from "../../assets/images/catalog/catalog-img1.avif";
import Image10 from "../../assets/images/catalog/catalog-img10.avif";
import Image11 from "../../assets/images/catalog/catalog-img11.avif";
import Image12 from "../../assets/images/catalog/catalog-img12.avif";
import Image13 from "../../assets/images/catalog/catalog-img13.avif";
import Image14 from "../../assets/images/catalog/catalog-img14.avif";
import Image15 from "../../assets/images/catalog/catalog-img15.avif";
import Image16 from "../../assets/images/catalog/catalog-img16.avif";
import Image17 from "../../assets/images/catalog/catalog-img17.avif";
import Image18 from "../../assets/images/catalog/catalog-img18.avif";
import Image02 from "../../assets/images/catalog/catalog-img2.avif";
import Image03 from "../../assets/images/catalog/catalog-img3.avif";
import Image04 from "../../assets/images/catalog/catalog-img4.avif";
import Image05 from "../../assets/images/catalog/catalog-img5.avif";
import Image06 from "../../assets/images/catalog/catalog-img6.avif";
import Image07 from "../../assets/images/catalog/catalog-img7.avif";
import Image08 from "../../assets/images/catalog/catalog-img8.avif";
import Image09 from "../../assets/images/catalog/catalog-img9.avif";
import HeroImage from "../../assets/images/heroes/catalog.avif";

export const meta = {
  title: "Catalog",
  description: "",
  keywords: [""],
};

export const hero = {
  image: {
    src: HeroImage,
    alt: "",
    width: 1000,
    height: 1000,
  },
  contents: [
    "Step into our world of unrivaled beauty and extraordinary creations. Every piece is a testament to the highest standards of craftsmanship, where artistry meets innovation in the pursuit of perfection.",
    "From dazzling diamonds to meticulously crafted gold and platinum jewelry, our collection offers a range of breathtaking designs that exude sophistication and grace.",
    "Whether you seek a statement piece or an heirloom to cherish for generations, explore our collection and discover jewelry that transcends time and trends, embodying the essence of luxury and exclusivity.",
  ],
};

export const images = [
  {
    image: Image01,
  },
  {
    image: Image02,
  },
  {
    image: Image03,
  },
  {
    image: Image04,
  },
  {
    image: Image05,
  },
  {
    image: Image06,
  },
  {
    image: Image07,
  },
  {
    image: Image08,
  },
  {
    image: Image09,
  },
  {
    image: Image10,
  },
  {
    image: Image11,
  },
  {
    image: Image12,
  },
  {
    image: Image13,
  },
  {
    image: Image14,
  },
  {
    image: Image15,
  },
  {
    image: Image16,
  },
  {
    image: Image17,
  },
  {
    image: Image18,
  },
];
