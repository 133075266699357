// packages
import { NavLink } from "react-router-dom";

// assets
// import Affiliate1 from "../assets/images/affiliates/footer-affiliate-1.png";
// import Affiliate2 from "../assets/images/affiliates/footer-affiliate-2.png";
// import Affiliate3 from "../assets/images/affiliates/footer-affiliate-3.png";
// import Affiliate4 from "../assets/images/affiliates/footer-affiliate-4.png";
// import Affiliate5 from "../assets/images/affiliates/footer-affiliate-5.png";
// import Affiliate6 from "../assets/images/affiliates/footer-affiliate-6.png";
// import Affiliate7 from "../assets/images/affiliates/footer-affiliate-7.png";
// import Affiliate8 from "../assets/images/affiliates/footer-affiliate-8.png";
// import Logo from "../assets/logos/logo-dark.avif";
// import Logo from "../assets/logos/logo-light.avif";

// components
import StandardContainer from "./StandardContainer";

// data
import { meta } from "../data/meta";
import { addresses, appendix, footer, social } from "../data/nav";

export default function Footer() {
  const navigationSection = (
    <div className="grid grid-cols-1 gap-6 py-4 sm:grid-cols-2 sm:gap-8 sm:py-8 lg:grid-cols-5 xl:py-24">
      <div className="flex flex-col max-sm:mb-4 max-sm:mt-2 sm:max-lg:col-span-2 lg:justify-between">
        <NavLink
          to="/"
          className="upcase text-center font-logo font-medium text-t-beige hover:text-t-skin max-lg:text-5xl lg:text-4xl xl:text-6xl"
        >
          {meta.title}
        </NavLink>
        <p className="text-center font-heading text-white/60">{meta.slogan}</p>
      </div>
      {Object.keys(footer).map((category, idx) => (
        <div
          key={idx}
          className=""
        >
          <p className="font-heading font-semibold uppercase text-white/70 lg:text-right">
            {category}
          </p>
          <ul className="mt-1 flex flex-col gap-y-1 sm:mt-4 md:gap-y-2 lg:text-right xl:mt-6">
            {Object.keys(footer[category]).map((item, itemIdx) => (
              <li key={itemIdx}>
                <NavLink
                  to={footer[category][item]}
                  className="text-white/80 hover:text-t-beige"
                >
                  {item}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      ))}
      <div className="flex flex-col gap-4 text-white/70 sm:justify-end lg:text-right lg:max-xl:text-sm">
        {addresses.map((address, idx) => (
          <p
            key={idx}
            className=""
          >
            {address.line1}
            <br />
            {address.line2}
          </p>
        ))}
      </div>
    </div>
  );

  // const affiliateSection = (
  //   <div className="grid justify-between grid-cols-2 px-4 py-6 bg-white max-lg:gap-2 md:grid-cols-4 lg:flex lg:flex-row">
  //     <img src={Affiliate1} alt="" className="object-contain" />
  //     <img src={Affiliate2} alt="" className="object-contain" />
  //     <img src={Affiliate3} alt="" className="object-contain" />
  //     <img src={Affiliate4} alt="" className="object-contain" />
  //     <img src={Affiliate5} alt="" className="object-contain" />
  //     <img src={Affiliate6} alt="" className="object-contain" />
  //     <img src={Affiliate7} alt="" className="object-contain" />
  //     <img src={Affiliate8} alt="" className="object-contain" />
  //   </div>
  // );

  const appendixSection = (
    <div className="grid grid-cols-1 gap-2 border-t border-white/5 py-4 max-sm:space-y-2 md:grid-cols-3 md:items-center md:justify-between">
      <ul className="flex justify-center gap-x-4 md:order-3 lg:justify-end">
        {Object.keys(social).map((key, idx) => (
          <li key={idx}>
            <NavLink
              className="text-white/50 hover:text-t-beige"
              to={social[key].url}
              target="_blank"
              title={key}
            >
              {social[key].icon}
            </NavLink>
          </li>
        ))}
      </ul>
      <ul className="flex justify-center gap-x-2 text-xs text-white/40 md:order-2">
        {Object.keys(appendix).map((key, idx) => (
          <li key={idx}>
            <NavLink
              className="text-white/40 hover:text-t-beige"
              title={key}
              to={appendix[key]}
            >
              {key}
            </NavLink>
          </li>
        ))}
      </ul>
      <p className="text-xs text-white/40 max-md:text-center md:order-1">
        &copy; {new Date().getFullYear()} {meta.title} &middot; All rights
        reserved &middot; Powered by White Diamond Inc
      </p>
    </div>
  );

  const content = (
    <>
      {navigationSection}
      {/* {affiliateSection} */}
      {appendixSection}
    </>
  );

  return (
    <footer className="bg-t-black">
      <StandardContainer content={content} />
    </footer>
  );
}
