import sliderImg24 from "../../assets/images/jewelry/categories/photos/large/Band - 30383.avif";
import sliderImg1 from "../../assets/images/slider-mini/27473.avif";
import sliderImg2 from "../../assets/images/slider-mini/28136 and 30027.avif";
import sliderImg3 from "../../assets/images/slider-mini/29080.avif";
import sliderImg4 from "../../assets/images/slider-mini/29567-2.avif";
import sliderImg5 from "../../assets/images/slider-mini/29567.avif";
import sliderImg6 from "../../assets/images/slider-mini/29611.avif";
import sliderImg7 from "../../assets/images/slider-mini/30036.avif";
import sliderImg8 from "../../assets/images/slider-mini/30377.avif";
import sliderImg9 from "../../assets/images/slider-mini/30385.avif";
import sliderImg10 from "../../assets/images/slider-mini/30520.avif";
import sliderImg11 from "../../assets/images/slider-mini/30864 and 30368.avif";
import sliderImg12 from "../../assets/images/slider-mini/30897.avif";
import sliderImg13 from "../../assets/images/slider-mini/30945.avif";
import sliderImg14 from "../../assets/images/slider-mini/31025.avif";
import sliderImg15 from "../../assets/images/slider-mini/31035.avif";
import sliderImg16 from "../../assets/images/slider-mini/31080.avif";
import sliderImg17 from "../../assets/images/slider-mini/31090.avif";
import sliderImg18 from "../../assets/images/slider-mini/31115-2.avif";
import sliderImg19 from "../../assets/images/slider-mini/31115.avif";
import sliderImg20 from "../../assets/images/slider-mini/31141.avif";
import sliderImg21 from "../../assets/images/slider-mini/31200.avif";
import sliderImg22 from "../../assets/images/slider-mini/31215.avif";
import sliderImg23 from "../../assets/images/slider-mini/31238.avif";

export const slider = [
  {
    image: {
      src: sliderImg1,
    },
  },
  {
    image: {
      src: sliderImg2,
    },
  },
  {
    image: {
      src: sliderImg3,
    },
  },
  {
    image: {
      src: sliderImg4,
    },
  },
  {
    image: {
      src: sliderImg5,
    },
  },
  {
    image: {
      src: sliderImg6,
    },
  },
  {
    image: {
      src: sliderImg7,
    },
  },
  {
    image: {
      src: sliderImg8,
    },
  },
  {
    image: {
      src: sliderImg9,
    },
  },
  {
    image: {
      src: sliderImg10,
    },
  },
  {
    image: {
      src: sliderImg11,
    },
  },
  {
    image: {
      src: sliderImg12,
    },
  },
  {
    image: {
      src: sliderImg13,
    },
  },
  {
    image: {
      src: sliderImg14,
    },
  },
  {
    image: {
      src: sliderImg15,
    },
  },
  {
    image: {
      src: sliderImg16,
    },
  },
  {
    image: {
      src: sliderImg17,
    },
  },
  {
    image: {
      src: sliderImg18,
    },
  },
  {
    image: {
      src: sliderImg19,
    },
  },
  {
    image: {
      src: sliderImg20,
    },
  },
  {
    image: {
      src: sliderImg21,
    },
  },
  {
    image: {
      src: sliderImg22,
    },
  },
  {
    image: {
      src: sliderImg23,
    },
  },
  {
    image: {
      src: sliderImg24,
    },
  },
];
