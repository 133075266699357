// packages
import { useState } from "react";
// TODO: replace icons
import {
  MdInfoOutline,
  MdOutlineCrisisAlert,
  MdOutlineTaskAlt,
} from "react-icons/md";
import { PiWarningLight, PiXSquareLight } from "react-icons/pi";

export default function Alert({ content, type, visible }) {
  const [isVisible, setIsVisible] = useState(visible);

  const ui = {
    error: {
      css: "bg-t-red text-white/90",
      icon: (
        <MdOutlineCrisisAlert
          size={16}
          className="mr-2 shrink-0"
        />
      ),
    },
    info: {
      css: "bg-t-blue text-white/90",
      icon: (
        <MdInfoOutline
          size={16}
          className="mr-2 shrink-0"
        />
      ),
    },
    success: {
      css: "bg-t-sky text-black/90",
      icon: (
        <MdOutlineTaskAlt
          size={16}
          className="mr-2 shrink-0"
        />
      ),
    },
    warning: {
      css: "bg-t-skin text-t-black",
      icon: (
        <PiWarningLight
          size={20}
          className="mr-2 shrink-0"
        />
      ),
    },
  };

  const hide = () => {
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div
        className={`${ui[type].css} my-2 flex w-full items-center p-3 md:my-4`}
        id="alert"
        role="alert"
      >
        {ui[type].icon}
        {content}
        <button
          aria-label="Close"
          className={`${ui[type].css} -mx-1.5 -my-1.5 ml-auto inline-flex h-8 w-8 items-center justify-center p-1.5 hover:bg-white/40`}
          onClick={() => hide()}
          type="button"
        >
          <PiXSquareLight
            size={20}
            className="shrink-0"
          />
        </button>
      </div>
    )
  );
}
