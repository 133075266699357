// components (template)
import Hero from "../../components/Hero";
import StandardContainer from "../../components/StandardContainer";

// data
import { body, hero, meta } from "../../data/contacts";

export default function Contact() {
  const content = (
    <div className="grid grid-cols-1 gap-4 lg:grid-cols-5 lg:gap-8 lg:text-center">
      {body.map((contact, idx) => {
        return (
          <div
            key={idx}
            className="flex flex-col gap-1.5"
          >
            <h2 className="s-h3 uppercase">{contact.title}</h2>
            <a
              href={contact.link.href}
              dangerouslySetInnerHTML={{ __html: contact.link.text }}
            />
            {contact.contents && (
              <ul className="align-self-end flex flex-col">
                {contact.contents.map((item, idx2) => {
                  return <li key={idx2}>{item}</li>;
                })}
              </ul>
            )}
          </div>
        );
      })}
    </div>
  );

  return (
    <>
      <Hero
        title={meta.title}
        image={hero.image}
        content={hero.contents}
      />
      <StandardContainer content={content} />;
    </>
  );
}
