// packages
import axios from "axios";
import { useEffect, useState } from "react";
import { MdOutlineDisabledByDefault } from "react-icons/md";
import {
  PiFileArrowDownLight,
  PiPaperclipHorizontalLight,
  PiPaperPlaneRightLight,
} from "react-icons/pi";
import { useNavigate, useParams } from "react-router-dom";

// components
import Header from "../components/Header";
import StandardContainer from "../components/StandardContainer";
import Alert from "../shared/components/Alert";
import LoadingIndicator from "../shared/components/LoadingIndicator";
import Table from "../shared/components/Table";

// utils
import { formattedCurrency } from "../shared/utils/currency";
import { formattedDate } from "../shared/utils/date";
import { isClientUser, isCompanyUser } from "../shared/utils/user";

// TODO: most of the fields are not being shown in the table(s)
export default function Quote() {
  const navigate = useNavigate();

  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  // quote data
  const [quote, setQuote] = useState({});
  const [companyNotes, setCompanyNotes] = useState("");
  const [file, setFile] = useState(null);
  const [diamondItems, _setDiamondItems] = useState([]);
  const [jewelryItems, _setJewelryItems] = useState([]);

  // quote states
  const [isRespondable, setIsRespondable] = useState(false);
  const [isResponded, setIsResponded] = useState(false);
  const [isSubmittable, setIsSubmittable] = useState(false);
  const [userRole, setUserRole] = useState(null);

  // profile
  useEffect(() => {
    const profile = JSON.parse(localStorage.profile);
    setUserRole(profile.role);
  }, []);

  // quote data
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/quotes/${id}.json`, {
        headers: {
          Authorization: localStorage.token,
        },
      })
      .then((response) => {
        setQuote(response.data);

        response.data.quote_items.forEach((item) => {
          const formattedItem = {
            ...item.item,
            price: formattedCurrency(item.item.price_1),
          };

          if (item.stone_id) {
            diamondItems.push(formattedItem);
          }
          if (item.jewelry_id) {
            jewelryItems.push(formattedItem);
          }
        });
      })
      .catch((error) => {
        console.error("Error fetching quote:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // quote state
  useEffect(() => {
    setIsRespondable(isCompanyUser(userRole) && quote.status === "requested");
    setIsResponded(quote.status === "responded");
  }, [quote, userRole]);

  const alertMessage = () => {
    let message;

    if (quote.status == "requested") {
      if (isCompanyUser(userRole)) {
        message =
          "This quote is awaiting your response. Please review, upload document and respond below.";
      } else if (isClientUser(userRole)) {
        message =
          "This quote has been submitted but has yet to be responded to by the company.";
      }
    } else if (quote.status == "responded") {
      if (isCompanyUser(userRole)) {
        message = "You have responded to this quote request.";
      } else if (isClientUser(userRole)) {
        message = "The company has responded to your quote request.";
      }
    }

    return message;
  };

  const handleAddFile = (e) => {
    const file = e.target.files[0];

    if (file) {
      setFile(file);
      setIsSubmittable(true);
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    setIsSubmittable(false);
  };

  const handleUpload = () => {
    const formData = new FormData();
    formData.append("quote[company_id]", quote.company_id);
    formData.append("quote[company_notes]", companyNotes);
    formData.append("quote[files][]", file);

    axios
      .patch(`${process.env.REACT_APP_API_URL}/quotes/${id}`, formData, {
        headers: {
          Authorization: localStorage.token,
        },
      })
      .then((_response) => {
        navigate("/quotes");
      })
      .catch((error) => console.error("Error submitting quote:", error));
  };

  //////////////////////////////////////////////////////////////////////////////
  // UI
  //////////////////////////////////////////////////////////////////////////////

  const tableColumnsDiamonds = [
    {
      key: "item_no",
      label: "Item Number",
      link: true,
      path: "/diamonds",
    },
    {
      key: "shape",
      label: "Shape",
      sortKey: "shape_name",
      classNames: "text-center",
    },
    {
      key: "carats",
      label: "Carats",
      sortKey: "carats",
      classNames: "text-right",
    },
    {
      key: "color",
      label: "Color",
      sortKey: "color_code",
      classNames: "text-center",
    },
    {
      key: "clarity",
      label: "Clarity",
      sortKey: "clarity_code",
      classNames: "text-center",
    },
    {
      key: "cut",
      label: "Cut",
      sortKey: "cut_code",
      classNames: "text-center",
    },
    {
      key: "price",
      label: "Price",
      sortKey: "price_total_2",
      classNames: "text-right",
    },
  ];

  const tableColumnsJewelry = [
    {
      key: "item_no",
      label: "Item Number",
      link: true,
      path: "/jewelry",
    },
    {
      key: "categoryDetails",
      label: "Category",
      sortKey: "category",
    },
    {
      key: "data.sub_category",
      label: "Sub-Category",
      sortKey: "sub_category",
    },
    { key: "description", label: "Description" },
    { key: "description_2", label: "Description 2" },
    {
      key: "price",
      label: "Price",
      sortKey: "price_total_1",
      classNames: "text-right",
    },
  ];

  const content = loading ? (
    <LoadingIndicator message="Loading quote details" />
  ) : (
    <>
      <Alert
        content={alertMessage()}
        type={quote.status == "requested" ? "error" : "success"}
        visible={true}
      />
      <div className="my-2 flex flex-col gap-4 md:flex-row">
        <div className="bg-gray-50 px-4 pb-4 md:flex-1">
          <dl className="space-y-4">
            <dt className="mr-2 inline-block font-bold">Quote ID:</dt>
            <dd className="inline-block">{quote.identifier}</dd>
            <br />
            <dt className="mr-2 inline-block font-bold">Status:</dt>
            <dd
              className={`${
                quote.status === "requested"
                  ? "bg-t-red text-white"
                  : "bg-t-sky text-black"
              } mr-2 inline-block px-2 py-1 text-sm font-semibold capitalize`}
            >
              {quote.status}
            </dd>
            <br />
            <dt className="mr-2 inline-block font-bold">Date:</dt>
            <dd className="inline-block">{formattedDate(quote.created_at)}</dd>
            <br />
            <dt className="mr-2 inline-block font-bold">
              {isCompanyUser(userRole) ? "Customer" : "Your"} Notes:
            </dt>
            <dd className="inline-block">
              {quote.customer_notes ? quote.customer_notes : "(none)"}
            </dd>
          </dl>
        </div>
        {(isRespondable || isResponded) && (
          <div className="flex flex-col gap-2 md:w-1/2">
            {isRespondable && (
              <>
                <textarea
                  className="w-full" // TODO: remove blue outline on focus
                  name="companyNotes"
                  placeholder="Additional notes..."
                  onChange={(e) => setCompanyNotes(e.target.value)}
                  rows={5}
                  value={companyNotes}
                />
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex flex-row gap-2">
                    <label
                      className={`${
                        file ? "bg-t-black text-white" : "bg-t-red text-white"
                      } flex w-full cursor-pointer items-center px-2 py-2.5 text-sm font-medium hover:bg-t-black`}
                      htmlFor="file"
                    >
                      <PiPaperclipHorizontalLight
                        className="mr-2 shrink-0"
                        size={20}
                      />
                      {file ? (
                        <span className="break-all text-sm">{file.name}</span>
                      ) : (
                        <span>Attach Quote Document</span>
                      )}
                      <input
                        hidden
                        id="file"
                        name="file"
                        onChange={handleAddFile}
                        type="file"
                      />
                    </label>
                    {file && (
                      <button
                        className="items-center bg-t-red px-1.5 py-2 text-sm font-medium text-white hover:bg-t-black"
                        onClick={handleRemoveFile}
                        title="Remove file"
                      >
                        <MdOutlineDisabledByDefault
                          className="shrink-0"
                          size={24}
                        />
                      </button>
                    )}
                  </div>
                  <button
                    className={`${
                      isSubmittable
                        ? "cursor-pointer bg-t-red text-white hover:bg-t-black"
                        : "cursor-not-allowed bg-t-gray text-white"
                    } flex w-full items-center px-2 py-2.5 text-sm font-medium`}
                    disabled={!isSubmittable}
                    onClick={handleUpload}
                  >
                    <PiPaperPlaneRightLight
                      className="mr-2 shrink-0"
                      size={18}
                    />
                    Send Quote Response
                  </button>
                </div>
              </>
            )}
            {isResponded && (
              <div className="flex h-full flex-col justify-between bg-gray-50 p-4">
                <dl className="space-y-4">
                  <dt className="mr-2 inline-block font-bold">
                    {isCompanyUser(userRole) ? "Your" : "Company"} Notes:
                  </dt>
                  <dd className="inline">
                    {quote.company_notes ? quote.company_notes : "(none)"}
                  </dd>
                </dl>
                {quote.files[0] && (
                  <a
                    className="mt-2 flex items-center bg-theme-blue px-2 py-2.5 text-center text-sm font-medium text-white hover:bg-t-black"
                    download
                    href={quote.files[0].url}
                  >
                    <PiFileArrowDownLight
                      className="mr-2 shrink-0"
                      size={20}
                    />
                    Download Quote Document
                  </a>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      {diamondItems.length > 0 && (
        <>
          <Header title="Diamonds" />
          {/* <h2 className="mt-4 text-lg font-bold text-center">Diamonds</h2> */}
          <Table
            data={diamondItems}
            columns={tableColumnsDiamonds}
          />
        </>
      )}
      {jewelryItems.length > 0 && (
        <>
          <Header title="Jewelry" />
          {/* <h2 className="mt-4 text-lg font-bold text-center">Jewelry</h2> */}
          <Table
            data={jewelryItems}
            columns={tableColumnsJewelry}
          />
        </>
      )}
    </>
  );

  return (
    <>
      <Header title="Quote Details" />
      <StandardContainer content={content} />
    </>
  );
}
