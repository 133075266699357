// components
import StandardContainer from "./StandardContainer";

// data
import { promos } from "../data/components/promos";

export default function Promos({ withoutContainer = false }) {
  const content = (
    <div className="my-4 grid grid-cols-1 gap-8 border border-black/5 bg-t-gray py-8 text-t-skin sm:my-8 md:grid-cols-3">
      {/* divide-black/20  max-sm:divide-y sm:divide-x */}
      {promos.map((promo, idx) => (
        <div
          key={idx}
          className="flex flex-col items-center gap-1 md:gap-3"
        >
          {/* <img className="w-8" src={promo.icon} /> */}
          {promo.icon}
          <p className="font-heading text-lg font-medium">{promo.text}</p>
        </div>
      ))}
    </div>
  );

  if (withoutContainer) {
    return content;
  } else {
    return <StandardContainer content={content} />;
  }
}
