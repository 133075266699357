import sliderImg1 from "../../assets/images/slider/banner2-fotor-2024092517391.avif";
import sliderImg2 from "../../assets/images/slider/banner3-fotor-20240925173748.avif";
import sliderImg3 from "../../assets/images/slider/banner3-new-fotor-20240925173349.avif";
import sliderImg4 from "../../assets/images/slider/banner4-fotor-2024092517358.avif";
import sliderImg5 from "../../assets/images/slider/banner5-fotor-2024092517368.avif";

export const bigSlider = [
  {
    image: {
      src: sliderImg1,
    },
  },
  {
    image: {
      src: sliderImg2,
    },
  },
  {
    image: {
      src: sliderImg3,
    },
  },
  {
    image: {
      src: sliderImg4,
    },
  },
  {
    image: {
      src: sliderImg5,
    },
  },
];
