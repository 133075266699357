// packages
import axios from "axios";
import { useEffect, useState } from "react";

// components
import LoadingIndicator from "../shared/components/LoadingIndicator";
import Pagination from "../shared/components/Pagination";
import Table from "../shared/components/Table";

// utils
// import { formattedCurrency } from "../utils/currency";
import { formattedDate } from "../shared/utils/date";
import { isCompanyUser } from "../shared/utils/user";

const DEFAULT_THEME = {
  // own settings

  routePrefix: "",
  tabTheme: {},
};

export default function QuoteList({ userId, userRole, theme = {} }) {
  const activeTheme = _.merge({}, DEFAULT_THEME, theme);

  const [loading, setLoading] = useState(true);

  //////////////////////////////////////////////////////////////////////////////
  // pagination
  //////////////////////////////////////////////////////////////////////////////

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const handlePageChange = ({ selected }) => {
    setSearchRequested(true);
    setCurrentPage(selected + 1);
  };

  //////////////////////////////////////////////////////////////////////////////
  // search
  //////////////////////////////////////////////////////////////////////////////

  const [data, setData] = useState([]);
  const [searchRequested, setSearchRequested] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [statusValue, setStatusValue] = useState("");

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleStatusChange = (e) => {
    setStatusValue(e.target.value);
  };

  const handleSearch = () => {
    setCurrentPage(1);
    setSearchRequested(true);
  };

  const handleReset = () => {
    setCurrentPage(1);
    setStatusValue("");
    setSearchQuery("");
    setSearchRequested(true);
  };

  useEffect(() => {
    if (searchRequested) {
      fetchData();
      setSearchRequested(false);
    }
  }, [currentPage, searchRequested]);

  const fetchData = () => {
    setLoading(true);

    const url = new URL(`${process.env.REACT_APP_API_URL}/quotes.json`);
    url.searchParams.append("page", currentPage);
    url.searchParams.append("search", searchQuery);
    url.searchParams.append("status", statusValue);
    if (userId) {
      url.searchParams.append("user_id", userId);
    }

    axios
      .get(url, { headers: { Authorization: localStorage.token } })
      .then((response) => {
        const transformedQuotes = response.data.data.map((item) => ({
          ...item,
          status: generateStatusHTML(item.status),
          customer: generateCustomerHTML(item.user),
          date: formattedDate(item.created_at),
          count: item.quote_items_count,
          // value: formattedCurrency(item.value),
        }));

        setData(transformedQuotes);
        setTotalPages(response.data.meta.page_count);
      })
      .catch((error) => {
        console.error("Error fetching quotes:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const generateCustomerHTML = (user) => (
    <div
      dangerouslySetInnerHTML={{
        __html: `<a href="/users/${user.id}" class="text-t-red">
          ${user.full_name}
        </a>`,
      }}
    />
  );

  const generateStatusHTML = (status) => (
    <div
      dangerouslySetInnerHTML={{
        __html: `<span class="${
          statusCssClasses[status]
        } px-2 py-1.5 font-medium">${_.capitalize(status)}</span>`,
      }}
    />
  );

  const statusCssClasses = {
    requested: "bg-t-red text-white",
    responded: "bg-t-sky text-black",
  };

  //////////////////////////////////////////////////////////////////////////////
  // UI
  //////////////////////////////////////////////////////////////////////////////

  const tableColumns = (userRole) => {
    let columns = [
      {
        key: "identifier",
        label: "Quote ID",
        link: true,
        path: `${activeTheme.routePrefix}/quotes`,
      },
      { key: "status", label: "Status" },
    ];

    if (isCompanyUser(userRole)) {
      columns.push({ key: "customer", label: "Customer" });
    }

    columns.push(
      { key: "date", label: "Date" },
      { key: "count", label: "Items", classNames: "text-right" },
      // { key: "value", label: "$ Value" },
    );

    return columns;
  };

  const statusInputs = [
    { label: "All", value: "" },
    { label: "Requested", value: "requested" },
    { label: "Responded", value: "responded" },
  ];

  const content = loading ? (
    <LoadingIndicator message="Loading quotes" />
  ) : (
    <>
      <div className="my-2 flex gap-2 max-md:flex-col">
        <div className="flex items-center md:flex-shrink">
          {/* TODO: change background & focus colors */}
          {statusInputs.map((input, index) => (
            <label
              className="mr-2 flex items-center gap-2"
              key={index}
            >
              <input
                checked={statusValue === input.value}
                onChange={handleStatusChange}
                type="radio"
                value={input.value}
              />
              <span>{input.label}</span>
            </label>
          ))}
        </div>
        <div className="flex gap-2 max-md:flex-col md:flex-1">
          <input
            className="sm:text-sm md:w-4/6" // TODO: remove blue outline on focus
            name="search"
            onChange={handleInputChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
            placeholder={`Search by ID${
              isCompanyUser(userRole) ? ", user name or email" : ""
            }`}
            type="text"
            value={searchQuery}
          />
          <button
            className="bg-t-red px-4 py-2 text-sm text-white md:w-1/6"
            onClick={handleSearch}
          >
            Search
          </button>
          <button
            className="bg-t-gray px-4 py-2 text-sm text-white md:w-1/6"
            onClick={handleReset}
          >
            Reset
          </button>
        </div>
      </div>
      <Pagination
        currentPage={currentPage - 1}
        onPageChange={handlePageChange}
        pageCount={totalPages}
      />
      <Table
        data={data}
        columns={tableColumns(userRole)}
      />
      <Pagination
        currentPage={currentPage - 1}
        onPageChange={handlePageChange}
        pageCount={totalPages}
      />
    </>
  );

  return content;
}
