// packages
import { PiCircleNotchLight } from "react-icons/pi";

export default function LoadingIndicator({ message }) {
  return (
    <div
      className="fixed left-0 right-0 top-0 z-50 flex h-full w-full items-center justify-center bg-t-black"
      tabIndex="-1"
    >
      <div
        className="max-h-2/6 max-w-2/6 flex flex-col items-center justify-center gap-8 bg-t-skin/70 p-10 sm:p-20"
        role="status"
      >
        <PiCircleNotchLight
          className="shrink-0 animate-spin"
          size={44}
        />
        <p className="s-h2 animate-pulse">{message}&hellip;</p>
      </div>
    </div>
  );
}
