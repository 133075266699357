// packages
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// assets
import "swiper/css";
import "swiper/css/navigation";

// components
import StandardContainer from "./StandardContainer";

// data
import { slider } from "../data/components/slider";

export default function Slider() {
  const content = (
    <div>
      <Swiper
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 10,
            slidesPerGroup: 1,
          },
          648: {
            slidesPerView: 2,
            spaceBetween: 30,
            slidesPerGroup: 2,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
            slidesPerGroup: 3,
          },
        }}
        autoplay={{
          delay: 2500,
        }}
        pagination={{
          enabled: true,
          clickable: true,
          dynamicBullets: true,
        }}
        navigation={{
          enabled: true,
        }}
        speed={1500}
        loop={true}
        modules={[Autoplay, Navigation, Pagination]}
        style={{
          "--swiper-pagination-color": "#404040",
          "--swiper-pagination-bullet-inactive-color": "#c6a58e",
          "--swiper-pagination-bullet-size": "1rem",
          "--swiper-pagination-bullet-border-radius": "0",
          "--swiper-navigation-color": "#404040",
          "--swiper-navigation-size": "2rem",
        }}
      >
        {slider.map((item, idx) => (
          <SwiperSlide key={idx}>
            <img
              src={item.image.src}
              alt=""
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );

  return <StandardContainer content={content} />;
}
