// packages
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// assets
import { NavLink } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";

export default function SliderWithText({
  slider,
  href,
  title,
  description,
  reverse = false,
}) {
  return (
    <div
      className={`${reverse && "md:flex-row-reverse"} flex gap-20 max-md:flex-col md:items-center`}
    >
      <div className="md:w-1/2">
        <Swiper
          modules={[Pagination]}
          loop={true}
          slidesPerView={1}
          spaceBetween={0}
          speed={1500}
          pagination={{
            enabled: true,
            clickable: true,
            dynamicBullets: true,
          }}
          style={{
            "--swiper-pagination-color": "#404040",
            "--swiper-pagination-bullet-inactive-color": "#c6a58e",
            "--swiper-pagination-bullet-size": "1rem",
            "--swiper-pagination-bullet-border-radius": "0",
          }}
        >
          {slider.map((item, idx) => (
            <SwiperSlide
              key={idx}
              className="border border-black/5"
            >
              <NavLink
                className="text-t-gray"
                to={href}
              >
                <img
                  className="size-full object-contain"
                  src={item.image.src}
                  alt=""
                />
              </NavLink>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="space-y-5 text-center md:w-1/2">
        <h2 className="s-h1">
          <NavLink
            className="text-t-gray"
            to={href}
          >
            {title}
          </NavLink>
        </h2>
        <p className="s-text">{description}</p>
      </div>
    </div>
  );
}
