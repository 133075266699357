////////////////////////////////////////////////////////////////////////////////
// packages
////////////////////////////////////////////////////////////////////////////////
import "flowbite";
import { BrowserRouter, Route, Routes } from "react-router-dom";

////////////////////////////////////////////////////////////////////////////////
// assets
////////////////////////////////////////////////////////////////////////////////
import "./index.css";

////////////////////////////////////////////////////////////////////////////////
// components
////////////////////////////////////////////////////////////////////////////////
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import ScrollToTop from "./components/ScrollToTop";

////////////////////////////////////////////////////////////////////////////////
// shared pages
////////////////////////////////////////////////////////////////////////////////
import DiamondsWrapper from "./shared/pages/DiamondsWrapper";
import JewelriesWrapper from "./shared/pages/JewelriesWrapper";
import Login from "./shared/pages/Login";

////////////////////////////////////////////////////////////////////////////////
// static pages
////////////////////////////////////////////////////////////////////////////////
import About from "./pages/static/About";
import Catalog from "./pages/static/Catalog";
import Contact from "./pages/static/Contact";
import HomeA from "./pages/static/HomeA";
import HomeB from "./pages/static/HomeB";
import HomeOld from "./pages/static/HomeOld";
import Privacy from "./pages/static/Privacy";
import Terms from "./pages/static/Terms";

////////////////////////////////////////////////////////////////////////////////
// template pages
////////////////////////////////////////////////////////////////////////////////
import Bookmarks from "./pages/Bookmarks";
import Diamonds from "./pages/Diamonds";
import Item from "./pages/Item";
import Jewelries from "./pages/Jewelries";
import PasswordReset from "./pages/PasswordReset";
import Profile from "./pages/Profile";
import Quote from "./pages/Quote";
import QuoteCart from "./pages/QuoteCart";
import Quotes from "./pages/Quotes";
import RequestPasswordReset from "./pages/RequestPasswordReset";

////////////////////////////////////////////////////////////////////////////////

export default function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Navbar />
        <Routes>
          {/* static routes */}
          <Route
            path="/"
            element={<HomeA />}
          />
          <Route
            path="/2"
            element={<HomeB />}
          />
          <Route
            path="/old"
            element={<HomeOld />}
          />
          <Route
            path="/about"
            element={<About />}
          />
          <Route
            path="/catalog"
            element={<Catalog />}
          />
          <Route
            path="/contact"
            element={<Contact />}
          />
          <Route
            path="/privacy"
            element={<Privacy />}
          />
          <Route
            path="/terms"
            element={<Terms />}
          />
          {/* dynamic routes */}
          <Route
            path="/diamonds"
            element={
              <DiamondsWrapper
                page={Diamonds}
                routeShow="/diamonds"
              />
            }
          />
          <Route
            path="/diamonds/:id"
            element={<Item type="diamond" />}
          />
          <Route
            path="/jewelry"
            element={
              <JewelriesWrapper
                page={Jewelries}
                routeShow="/jewelry"
              />
            }
          />
          <Route
            path="/jewelry/:id"
            element={<Item type="jewelry" />}
          />
          {/* guest routes */}
          <Route
            path="/sign-in"
            element={
              <Login
                theme={{
                  // own settings
                  button: {
                    primary:
                      "bg-t-red text-white tracking-wider uppercase hover:bg-black active:bg-black/50",
                  },
                  checkbox:
                    "border-gray-400 bg-gray-50 hover:bg-gray-200 checked:bg-t-red checked:hover:bg-t-red/50 checked:focus:bg-t-red/50",
                  container: "bg-t-black mb-2 md:mb-4",
                  input:
                    "border-gray-400 bg-gray-50 font-bold text-t-gray focus:border-t-red",
                  label: "font-semibold text-t-gray",
                  link: "font-semibold",
                  title: "text-gray-600",
                  window: "bg-white",

                  // component settings
                  tabTheme: {
                    container: "bg-black",
                    button: {
                      all: "border-none w-full block font-bold text-lg text-t-red tracking-wide",
                      active: "border-white bg-white !text-t-gray cursor-none",
                      inactive:
                        "border-transparent text-gray-300 hover:text-white",
                    },
                    label: "w-full",
                  },
                }}
              />
            }
          />
          <Route
            path="/request-password-reset"
            element={
              <RequestPasswordReset
                theme={{
                  // own settings
                  button: {
                    primary:
                      "bg-t-red text-white tracking-wider uppercase hover:bg-black active:bg-black/50",
                  },
                  checkbox:
                    "border-gray-400 bg-gray-50 hover:bg-gray-200 checked:bg-t-red checked:hover:bg-t-red/50 checked:focus:bg-t-red/50",
                  container: "bg-t-black mb-2 md:mb-4",
                  input:
                    "border-gray-400 bg-gray-50 font-bold text-t-gray focus:border-t-red",
                  label: "font-semibold text-t-gray",
                  link: "font-semibold",
                  title: "text-gray-600",
                  window: "bg-white",

                  // component settings
                  tabTheme: {
                    container: "bg-black",
                    button: {
                      all: "border-none w-full block font-bold text-lg text-t-red tracking-wide",
                      active: "border-white bg-white !text-t-gray cursor-none",
                      inactive:
                        "border-transparent text-gray-300 hover:text-white",
                    },
                    label: "w-full",
                  },
                }}
              />
            }
          />
          <Route
            path="/password-reset/:resetToken"
            element={
              <PasswordReset
                theme={{
                  // own settings
                  button: {
                    primary:
                      "bg-t-red text-white tracking-wider uppercase hover:bg-black active:bg-black/50",
                  },
                  checkbox:
                    "border-gray-400 bg-gray-50 hover:bg-gray-200 checked:bg-t-red checked:hover:bg-t-red/50 checked:focus:bg-t-red/50",
                  container: "bg-t-black mb-2 md:mb-4",
                  input:
                    "border-gray-400 bg-gray-50 font-bold text-t-gray focus:border-t-red",
                  label: "font-semibold text-t-gray",
                  link: "font-semibold",
                  title: "text-gray-600",
                  window: "bg-white",

                  // component settings
                  tabTheme: {
                    container: "bg-black",
                    button: {
                      all: "border-none w-full block font-bold text-lg text-t-red tracking-wide",
                      active: "border-white bg-white !text-t-gray cursor-none",
                      inactive:
                        "border-transparent text-gray-300 hover:text-white",
                    },
                    label: "w-full",
                  },
                }}
              />
            }
          />
          {/* user routes */}
          <Route
            path="/profile"
            element={<Profile />}
          />
          <Route
            path="/bookmarks"
            element={<Bookmarks />}
          />
          <Route
            path="/quotes"
            element={<Quotes />}
          />
          <Route
            path="/quotes/:id"
            element={<Quote routeShow="/quotes/:id" />}
          />
          <Route
            path="/quotes/cart"
            element={<QuoteCart routeShow="/quotes/cart" />}
          />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}
