export const meta = {
  url: "https://www.ninacci.com",
  title: "Ninacci",
  slogan: "Designers & Manufacturers of Fine Diamond Jewelry",
  description: "", // TODO
  keywords: [""], // TODO
  chat: "https://wa.me/12136244400",
  email: "sales@ninacci.com",
  phone: "+12136244400",
  phoneFormatted: "(213) 624-4400",
};
