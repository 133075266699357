// components (shared)
import DiamondsUI from "../shared/components/DiamondsUI";

// components (template)
// import Header from "../components/Header";
// import ImageBanner from "../components/ImageBanner";
import StandardContainer from "../components/StandardContainer";

// data
// import { categories } from "../data/categories";

export default function Diamonds() {
  return (
    <>
      {/* <Header title="Diamond Search" /> */}
      {/* <ImageBanner image={categories.diamonds.banner} /> */}
      <div className="my-4 sm:my-8">
        <StandardContainer content={<DiamondsUI />} />
      </div>
    </>
  );
}
