// assets
// import BannerDiamonds from "../assets/images/banners/old/diamonds.jpg";
// import BannerJewelry from "../assets/images/banners/old/jewelry.jpg";
import BannerDiamonds from "../assets/images/banners/diamonds.avif";
import BannerJewelry from "../assets/images/banners/jewelry.avif";

import DiamondIconAsscher from "../shared/assets/images/diamonds/shapes/photos/thumb/asscher.jpg";
import DiamondIconCushion from "../shared/assets/images/diamonds/shapes/photos/thumb/cushion.jpg";
import DiamondIconEmerald from "../shared/assets/images/diamonds/shapes/photos/thumb/emerald.jpg";
import DiamondIconHeart from "../shared/assets/images/diamonds/shapes/photos/thumb/heart.jpg";
import DiamondIconMarquise from "../shared/assets/images/diamonds/shapes/photos/thumb/marquise.jpg";
import DiamondIconOval from "../shared/assets/images/diamonds/shapes/photos/thumb/oval.jpg";
import DiamondIconPear from "../shared/assets/images/diamonds/shapes/photos/thumb/pear.jpg";
import DiamondIconPrincess from "../shared/assets/images/diamonds/shapes/photos/thumb/princess.jpg";
import DiamondIconRadiant from "../shared/assets/images/diamonds/shapes/photos/thumb/radiant.jpg";
import DiamondIconRound from "../shared/assets/images/diamonds/shapes/photos/thumb/round.jpg";

// import JewelrySketchAnklet from "../shared/assets/images/jewelry/categories-old/w-anklet.png";
// import JewelrySketchBracelet from "../shared/assets/images/jewelry/categories-old/w-bracelet.png";
// // import JewelrySketchBrooch from "../shared/assets/images/jewelry/categories-old/w-brooch.png";
// // import JewelrySketchCharm from "../shared/assets/images/jewelry/categories-old/w-charm.png";
// // import JewelrySketchCufflink from "../shared/assets/images/jewelry/categories-old/w-cufflink.png";
// import JewelrySketchEarring from "../shared/assets/images/jewelry/categories-old/w-earring.png";
// import JewelrySketchNecklace from "../shared/assets/images/jewelry/categories-old/w-necklace.png";
// import JewelrySketchPendant from "../shared/assets/images/jewelry/categories-old/w-pendant.png";
// import JewelrySketchRing from "../shared/assets/images/jewelry/categories-old/w-ring.png";
// import JewelrySketchStud from "../shared/assets/images/jewelry/categories-old/w-stud.png";

import Band from "../assets/images/jewelry/categories/photos/large/Band - 30383.avif";
import Bracelet from "../assets/images/jewelry/categories/photos/large/Bracelet - 30385.avif";
import Earring from "../assets/images/jewelry/categories/photos/large/Earring - 25582.avif";
import Necklace from "../assets/images/jewelry/categories/photos/large/Necklace - 31025.avif";
import Ring from "../assets/images/jewelry/categories/photos/large/Ring - 27610.avif";

export const categories = {
  diamonds: {
    title: "Browse By Shape",
    // subtitle:
    //   "Browse our extensive collection of loose polished diamonds in all shapes and sizes",
    banner: BannerDiamonds,
    path: "/diamonds",
    param: "shape",
    imageCssClasses: "object-cover w-16",
    categories: [
      {
        name: "Round",
        code: "RD",
        image: DiamondIconRound,
      },
      {
        name: "Oval",
        code: "OV",
        image: DiamondIconOval,
      },
      {
        name: "Cushion",
        code: "CU",
        image: DiamondIconCushion,
      },
      {
        name: "Pear",
        code: "PR",
        image: DiamondIconPear,
      },
      {
        name: "Princess",
        code: "PS",
        image: DiamondIconPrincess,
      },
      {
        name: "Emerald",
        code: "EC",
        image: DiamondIconEmerald,
      },
      {
        name: "Marquise",
        code: "MQ",
        image: DiamondIconMarquise,
      },
      {
        name: "Asscher",
        code: "AS",
        image: DiamondIconAsscher,
      },
      {
        name: "Radiant",
        code: "RA",
        image: DiamondIconRadiant,
      },
      {
        name: "Heart",
        code: "HS",
        image: DiamondIconHeart,
      },
    ],
  },
  jewelry: {
    title: "Browse By Category",
    // subtitle: "Discover our exquisite jewelry collection",
    banner: BannerJewelry,
    path: "/jewelry",
    param: "category",
    imageCssClasses: "object-none w-16",
    categories: [
      {
        name: "Rings",
        code: "RING",
        image: Ring,
        // "https://img.icons8.com/external-jumpicon-glyph-ayub-irawan/64/444444/external-Diamond-Ring-jewelry-jumpicon-(glyph)-jumpicon-glyph-ayub-irawan.png",
        // "https://img.icons8.com/external-dreamstale-lineal-dreamstale/64/external-ring-accessories-dreamstale-lineal-dreamstale.png",
      },
      {
        name: "Bands",
        code: "BAND",
        image: Band,
        // "https://img.icons8.com/external-jumpicon-glyph-ayub-irawan/64/444444/external-Bracelet-jewelry-jumpicon-(glyph)-jumpicon-glyph-ayub-irawan.png",
        // "https://img.icons8.com/external-dreamstale-lineal-dreamstale/64/external-scarf-accessories-dreamstale-lineal-dreamstale.png",
      },
      {
        name: "Bracelets",
        code: "BRACELET",
        image: Bracelet,
        // "https://img.icons8.com/external-jumpicon-glyph-ayub-irawan/64/444444/external-Bracelet-jewelry-jumpicon-(glyph)-jumpicon-glyph-ayub-irawan-2.png",
        // "https://img.icons8.com/external-dreamstale-lineal-dreamstale/64/external-bracelet-accessories-dreamstale-lineal-dreamstale.png",
      },
      // {
      //   name: "Semi",
      //   code: "SEMI-MOUNT",
      //   image: JewelrySketchStud,
      //   // "https://img.icons8.com/external-jumpicon-glyph-ayub-irawan/64/444444/external-Earrings-jewelry-jumpicon-(glyph)-jumpicon-glyph-ayub-irawan-2.png",
      //   // "https://img.icons8.com/external-dreamstale-lineal-dreamstale/64/external-earring-accessories-dreamstale-lineal-dreamstale.png",
      // },
      {
        name: "Earrings",
        code: "EARRING",
        image: Earring,
        // "https://img.icons8.com/external-jumpicon-glyph-ayub-irawan/64/444444/external-Earrings-jewelry-jumpicon-(glyph)-jumpicon-glyph-ayub-irawan-4.png",
        // "https://img.icons8.com/external-dreamstale-lineal-dreamstale/64/external-earrings-accessories-dreamstale-lineal-dreamstale.png",
      },
      // {
      //   name: "Studs",
      //   code: "Stud",
      //   image: JewelrySketchStud,
      //   // "https://img.icons8.com/external-jumpicon-glyph-ayub-irawan/64/444444/external-Earrings-jewelry-jumpicon-(glyph)-jumpicon-glyph-ayub-irawan-2.png",
      //   // "https://img.icons8.com/external-dreamstale-lineal-dreamstale/64/external-earring-accessories-dreamstale-lineal-dreamstale.png",
      // },
      {
        name: "Necklaces",
        code: "NECKLACE",
        image: Necklace,
        // "https://img.icons8.com/external-jumpicon-glyph-ayub-irawan/64/444444/external-Necklace-jewelry-jumpicon-(glyph)-jumpicon-glyph-ayub-irawan-2.png",
        // "https://img.icons8.com/external-dreamstale-lineal-dreamstale/64/external-necklace-accessories-dreamstale-lineal-dreamstale.png",
      },
      // {
      //   name: "Pendants",
      //   code: "PENDANT",
      //   image: JewelrySketchPendant,
      //   // "https://img.icons8.com/external-jumpicon-glyph-ayub-irawan/64/444444/external-Brooch-jewelry-jumpicon-(glyph)-jumpicon-glyph-ayub-irawan.png",
      //   // "https://img.icons8.com/external-dreamstale-lineal-dreamstale/64/external-necklace-accessories-dreamstale-lineal-dreamstale-1.png",
      // },

      // {
      //   name: "Charms",
      //   code: "Charm",
      //   image: JewelrySketchCharm,
      //   // "https://img.icons8.com/external-jumpicon-glyph-ayub-irawan/64/444444/external-Gemstone-jewelry-jumpicon-(glyph)-jumpicon-glyph-ayub-irawan-5.png",
      //   // "https://img.icons8.com/external-dreamstale-lineal-dreamstale/64/external-tag-accessories-dreamstale-lineal-dreamstale.png",
      // },
      // {
      //   name: "Anklets",
      //   code: "Anklet",
      //   image: JewelrySketchAnklet,
      //   // "https://img.icons8.com/external-jumpicon-glyph-ayub-irawan/64/444444/external-Bracelet-jewelry-jumpicon-(glyph)-jumpicon-glyph-ayub-irawan.png",
      //   // "https://img.icons8.com/external-dreamstale-lineal-dreamstale/64/external-scarf-accessories-dreamstale-lineal-dreamstale.png",
      // },
      // {
      //   name: "Brooches",
      //   code: "Brooch",
      //   image: JewelrySketchBrooch,
      //   // "https://img.icons8.com/external-jumpicon-glyph-ayub-irawan/64/444444/external-Brooch-jewelry-jumpicon-(glyph)-jumpicon-glyph-ayub-irawan-6.png",
      //   // "https://img.icons8.com/external-dreamstale-lineal-dreamstale/64/external-mirror-accessories-dreamstale-lineal-dreamstale.png",
      // },
      // {
      //   name: "Cufflinks",
      //   code: "Cufflink",
      //   image: JewelrySketchCufflink,
      //   // "https://img.icons8.com/external-jumpicon-glyph-ayub-irawan/64/444444/external-Stud-Earrings-jewelry-jumpicon-(glyph)-jumpicon-glyph-ayub-irawan.png",
      //   // "https://img.icons8.com/external-dreamstale-lineal-dreamstale/64/external-slippers-accessories-dreamstale-lineal-dreamstale.png",
      // },
    ],
  },
};
