// packages
import _ from "lodash";
import { PiArrowSquareRightLight, PiXSquareLight } from "react-icons/pi";

// TODO: fix issue with modal not closing when clicking outside or pressing Esc
//  as currently implemented, it acts as a static modal instead of dynamic

const DEFAULT_THEME = {
  button: {
    primary: "bg-t-red text-t-skin hover:bg-t-gray hover:text-t-sky",
  },
  header: "text-t-black",
  input: "border-black/5 bg-gray-50 focus:border-t-beige",
  label: "text-t-black",
  overlay: "bg-t-black/90",
  window: "bg-t-skin",
};

export default function ModalInquiry({
  header,
  isModalOpen,
  setIsModalOpen,
  submitText = "Submit",
  theme = {},
}) {
  const activeTheme = _.merge({}, DEFAULT_THEME, theme);

  // https://flowbite.com/docs/components/modal/#default-modal
  return (
    isModalOpen && (
      <div
        aria-hidden="true"
        className={`${activeTheme.overlay} fixed left-0 right-0 top-0 z-50 h-full max-h-full w-full overflow-y-auto overflow-x-hidden max-md:px-2 md:inset-0`}
        tabIndex="-1"
      >
        <div className="relative flex h-full max-h-full w-full items-center justify-center">
          <div
            className={`${activeTheme.window} relative w-full space-y-5 md:p-4 lg:w-5/12 xl:p-20`}
          >
            {/* header */}
            <button
              className="absolute right-5 top-5 bg-transparent text-t-beige hover:text-t-red"
              onClick={() => setIsModalOpen(false)}
              type="button"
            >
              <PiXSquareLight
                size={32}
                className="shrink-0"
              />
            </button>
            <div
              className={`${activeTheme.header} flex justify-center max-sm:px-4 max-sm:pt-8`}
            >
              <h3 className="s-h4">{header}</h3>
            </div>
            {/* content */}
            {/* TODO */}
            <form
              className="space-y-2 p-2 md:space-y-4"
              // onSubmit={handleSubmit}
              // ref={formRef}
            >
              <div className="grid w-full grid-cols-1 gap-2 sm:grid-cols-2 md:gap-4 xl:gap-6">
                <div>
                  {/* <label
                        className={`${activeTheme.label} block text-sm font-medium`}
                        htmlFor="iName"
                      >
                        Name
                      </label> */}
                  <input
                    className={`${activeTheme.input} w-full border p-2 focus:ring-0`}
                    id="iName"
                    name="name"
                    placeholder="Your Name"
                    required="required"
                    type="text"
                  />
                </div>
                <div>
                  {/* <label
                        className={`${activeTheme.label} block text-sm font-medium`}
                        htmlFor="iLastName"
                      >
                        Name
                      </label> */}
                  <input
                    className={`${activeTheme.input} w-full border p-2 focus:ring-0`}
                    id="iLastName"
                    name="lastName"
                    placeholder="Your Last Name"
                    required="required"
                    type="text"
                  />
                </div>
                <div>
                  {/* <label
                        className={`${activeTheme.label} block text-sm font-medium`}
                        htmlFor="iEmail"
                      >
                        Email
                      </label> */}
                  <input
                    className={`${activeTheme.input} w-full border p-2 focus:ring-0`}
                    id="iEmail"
                    name="email"
                    placeholder="Your email address"
                    required="required"
                    type="email"
                  />
                </div>
                <div>
                  {/* <label
                        className={`${activeTheme.label} block text-sm font-medium`}
                        htmlFor="iPhone"
                      >
                        Phone
                      </label> */}
                  <input
                    className={`${activeTheme.input} w-full border p-2 focus:ring-0`}
                    id="iPhone"
                    name="phone"
                    placeholder="Your phone number"
                    type="tel"
                  />
                </div>
                <div className="col-span-full">
                  {/* <label
                        className={`${activeTheme.label} block text-sm font-medium`}
                        htmlFor="iComments"
                      >
                        Comments
                      </label> */}
                  <textarea
                    className={`${activeTheme.input} w-full border p-2 focus:ring-0`}
                    id="iComments"
                    name="comments"
                    rows={15}
                    placeholder="Your questions or comments"
                  ></textarea>
                </div>
              </div>
              <button
                className={`${activeTheme.button.primary} s-text group mt-6 flex w-full items-center justify-center gap-2 px-4 py-3 font-medium focus:ring-0`}
                type="submit"
              >
                {submitText}
                <PiArrowSquareRightLight
                  className="shrink-0"
                  size={20}
                />
              </button>
            </form>
          </div>
        </div>
      </div>
    )
  );
}
