// components (template)
import Hero from "../../components/Hero";

// data
import { hero, meta } from "../../data/pages/terms";

export default function Terms() {
  return (
    <Hero
      title={meta.title}
      image={hero.image}
      content={hero.contents}
    />
  );
}
