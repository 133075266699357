// packages
import { MdOutlineNavigateBefore, MdOutlineNavigateNext } from "react-icons/md";
import ReactPaginate from "react-paginate";

// https://flowbite.com/docs/components/pagination/#default-pagination
export default function Pagination({ pageCount, onPageChange, currentPage }) {
  const sharedCssClasses =
    "flex items-center justify-center px-3 py-2 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-800";

  // if (pageCount > 1) {
  return (
    <div className="flex">
      <ReactPaginate
        pageCount={pageCount}
        forcePage={currentPage}
        onPageChange={onPageChange}
        pageRangeDisplayed={4}
        marginPagesDisplayed={1}
        containerClassName="flex divide-x divide-gray-200 bg-gray-50 text-sm"
        activeClassName="bg-white"
        activeLinkClassName="text-t-black font-semibold"
        breakClassName={sharedCssClasses}
        nextClassName={`${sharedCssClasses}`}
        nextLabel={<MdOutlineNavigateNext />}
        pageLinkClassName={sharedCssClasses}
        previousClassName={`${sharedCssClasses}`}
        previousLabel={<MdOutlineNavigateBefore />}
      />
    </div>
  );
  // }
}
