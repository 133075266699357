export default function Header({
  title,
  bottomBorder = true,
  topBorder = false,
}) {
  return (
    <h1
      className={` ${bottomBorder && "border-b pb-4"} ${topBorder && "border-t pt-8"} s-h1 my-4 border-black/5 text-center sm:my-8`}
    >
      {title}
    </h1>
  );
}
