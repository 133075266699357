// packages
import axios from "axios";
import _ from "lodash";
import { useRef, useState } from "react";
import { useParams } from "react-router-dom";

// components
import Alert from "../shared/components/Alert";
import Tabs from "../shared/components/Tabs";

const DEFAULT_THEME = {
  // own settings
  button: {
    primary:
      "bg-theme-blue text-white rounded-md hover:bg-black active:bg-black/50",
  },
  checkbox:
    "border-gray-400 bg-gray-50 rounded hover:bg-gray-200 checked:bg-theme-blue checked:hover:bg-theme-blue/50 checked:focus:bg-theme-blue/50",
  container: "bg-theme-blue",
  input:
    "border-gray-400 bg-gray-50 text-gray-800 rounded-md focus:border-theme-blue",
  label: "text-gray-800",
  link: "text-theme-blue hover:text-gray-800 hover:underline",
  title: "text-gray-600 leading-tight tracking-tight",
  window: "rounded-md bg-white",

  // component settings
  tabTheme: {},
};

export default function PasswordReset({ theme = {} }) {
  const { resetToken } = useParams();

  const activeTheme = _.merge({}, DEFAULT_THEME, theme);

  const [alertMessage, setAlertMessage] = useState("");
  const [alertVisible, setAlertVisible] = useState(false);

  const [resetMessage, setResetMessage] = useState("");
  const [resetVisible, setResetVisible] = useState(false);

  // email verify

  const passwordResetForm = useRef();
  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setAlertVisible(false);
    const formData = new FormData(passwordResetForm.current);
    const entries = Object.fromEntries(formData);

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/password`,
        {
          user: {
            password: entries.password,
            password_confirmation: entries.password_confirmation,
            reset_password_token: resetToken,
          },
        },
      );
      setResetVisible(true);
      setResetMessage("Password changed successfully");
    } catch (error) {
      console.error("Error reset password:", error);
      e.preventDefault();
      setAlertVisible(true);
      setAlertMessage("Passwords did not match, please try again");
    }
  };

  const tabData = [
    {
      label: "Reset Password",
      content: (
        <div
          className={`${activeTheme.container} mx-auto flex flex-col items-center justify-center p-4 lg:py-6 xl:py-8`}
          // md:h-[calc(100vh-8rem)]
        >
          <div className="w-full md:max-w-md">
            {alertVisible && (
              <Alert
                content={alertMessage}
                type="error"
                visible={alertVisible}
              />
            )}
            <div className={activeTheme.window}>
              {resetVisible ? (
                <Alert
                  content={resetMessage}
                  type="success"
                  visible={resetVisible}
                />
              ) : (
                <div className="space-y-4 p-6 sm:p-8">
                  <h1 className={`${activeTheme.title} s-h2 mb-8`}>
                    Reset Your Password
                  </h1>
                  <form
                    className="space-y-4"
                    onSubmit={handlePasswordReset}
                    ref={passwordResetForm}
                  >
                    <div>
                      <label
                        className={`${activeTheme.label} block text-sm font-medium`}
                        htmlFor="newPassword"
                      >
                        New Password
                      </label>
                      <input
                        className={`${activeTheme.input} mt-2 block w-full border p-2 focus:ring-0 sm:text-sm`}
                        id="newPassword"
                        name="password"
                        placeholder="New Password"
                        required="required"
                        type="password"
                      />
                    </div>
                    <div>
                      <label
                        className={`${activeTheme.label} block text-sm font-medium`}
                        htmlFor="confirmPassword"
                      >
                        Confirm Password
                      </label>
                      <input
                        className={`${activeTheme.input} mt-2 block w-full border p-2 focus:ring-0 sm:text-sm`}
                        id="confirmPassword"
                        name="password_confirmation"
                        placeholder="Confirm Password"
                        required="required"
                        type="password"
                      />
                    </div>

                    <button
                      className={`${activeTheme.button.primary} w-full px-5 py-2.5 text-center text-sm font-bold focus:ring-0`}
                      type="submit"
                    >
                      Change Password
                    </button>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <Tabs
      data={tabData}
      theme={activeTheme.tabTheme}
    />
  );
}
