// packages
import { PiEnvelopeLight, PiGiftLight, PiHandHeartLight } from "react-icons/pi";

export const promos = [
  {
    icon: <PiGiftLight className="text-3xl" />,
    text: "Free Gift Wrapping",
  },
  {
    icon: <PiEnvelopeLight className="text-3xl" />,
    text: "Complimentary Delivery",
  },
  {
    icon: <PiHandHeartLight className="text-3xl" />,
    text: "Easy Return Or Exchange",
  },
];
