// packages
import _ from "lodash";
import { useEffect, useState } from "react";

const DEFAULT_THEME = {
  container: "rounded-md bg-gray-50",
  button: {
    all: "hover:border-t-beige hover:text-t-beige",
    active: "border-t-red text-t-red",
    inactive: "border-gray-50",
  },
  label: "",
};

export default function Tabs({ data, theme = {} }) {
  const activeTheme = _.merge({}, DEFAULT_THEME, theme);

  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    setActiveTab(tabId(0));
  }, []);

  const handleTabClick = (label) => {
    setActiveTab(tabId(label));
  };

  const tabId = (index) => `tab-content-${index}`;

  // https://flowbite.com/docs/components/tabs/
  return (
    <>
      <div className={activeTheme.container}>
        <ul
          className="flex text-sm font-medium"
          id="tab-labels"
          role="tablist"
        >
          {data.map((tab, index) => (
            <li
              className={activeTheme.label}
              key={index}
              role="presentation"
            >
              <button
                aria-controls={tabId(index)}
                aria-selected={activeTab === tabId(index)}
                className={`${activeTheme.button.all} ${
                  activeTab === tabId(index)
                    ? activeTheme.button.active
                    : activeTheme.button.inactive
                } border-b-4 px-4 pb-3 pt-4`}
                id={`tab-label-${index}`}
                onClick={() => handleTabClick(index)}
                role="tab"
                type="button"
              >
                {tab.label}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div id="tab-contents">
        {data.map((tab, index) => (
          <div
            aria-labelledby={`tab-label-${index}`}
            className={`${activeTab === tabId(index) ? "" : "hidden"}`}
            id={tabId(index)}
            key={index}
            role="tabpanel"
          >
            {tab.content}
          </div>
        ))}
      </div>
    </>
  );
}
