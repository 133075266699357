// packages
import axios from "axios";
import { useEffect, useState } from "react";
import { PiArrowSquareRightLight, PiXSquareLight } from "react-icons/pi";

// components
// TODO: refactor to use the existing Modal component
import Header from "../components/Header";
import StandardContainer from "../components/StandardContainer";
import Alert from "../shared/components/Alert";
import LoadingIndicator from "../shared/components/LoadingIndicator";

const DEFAULT_THEME = {
  button: {
    primary: "bg-t-red text-t-skin hover:bg-t-gray hover:text-t-sky",
  },
  container: "bg-t-black text-white",
  overlay: "bg-t-black/90",
  link: "text-t-red",
};

export default function Profile({ theme = {} }) {
  const activeTheme = _.merge({}, DEFAULT_THEME, theme);

  // data
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);

  // states
  const [alertMessage, setAlertMessage] = useState("");
  const [alertVisible, setAlertVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [updateAttribute, setUpdateAttribute] = useState("");
  const [updateSuccessful, setUpdateSuccessful] = useState(false);

  useEffect(() => {
    const profile = JSON.parse(localStorage.profile);
    setUser(profile);
  }, []);

  const fetchUser = () => {
    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_URL}/profile`, {
        headers: {
          Authorization: localStorage.token,
        },
      })
      .then((response) => {
        setUser(response.data);
      })
      .catch((error) => {
        // alert("Error fetching profile");
        console.error("Error fetching profile:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const openModal = (type) => {
    setUpdateAttribute(type);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleUpdate = async (e, attribute, data, successMessage) => {
    e.preventDefault();
    setAlertVisible(false);

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/${attribute}`,
        data,
        {
          headers: {
            Authorization: localStorage.token,
          },
        },
      );

      fetchUser();
      setAlertMessage(successMessage);
      setUpdateSuccessful(true);
    } catch (error) {
      console.error(`Error updating ${attribute}:`, error);
      setAlertMessage(`Error updating your details, please try again.`);
      setUpdateSuccessful(false);
    } finally {
      closeModal();
      setAlertVisible(true);
    }
  };

  const form = () => {
    const handleSubmit = async (e) => {
      const formData = new FormData(e.target);
      const data = {};

      formData.forEach((value, key) => {
        data[key] = value;
      });

      if (
        updateAttribute === "password" &&
        data.password !== data.password_confirmation
      ) {
        e.preventDefault();
        setAlertMessage("New password and confirmation did not match.");
        setAlertVisible(true);
        setModalVisible(false);
        setUpdateSuccessful(false);
        return;
      }

      handleUpdate(
        e,
        `profile/${updateAttribute}`,
        { user: data },
        `Your ${updateAttribute} has been updated.`,
      );
    };

    return (
      <div
        aria-hidden="true"
        className={`${activeTheme.overlay} fixed left-0 right-0 top-0 z-50 h-full max-h-full w-full overflow-y-auto overflow-x-hidden max-md:px-2 md:inset-0`}
        tabIndex="-1"
      >
        <div className="relative flex h-full max-h-full w-full items-center justify-center">
          <div className="relative w-full bg-white shadow lg:w-1/2">
            <form onSubmit={handleSubmit}>
              {/* header */}
              <div
                className={`${activeTheme.container} flex items-center justify-between border-b p-4 text-white`}
              >
                <h3 className="s-h4">
                  {updateAttribute === "email" && "Update Email"}
                  {updateAttribute === "password" && "Update Password"}
                  {updateAttribute === "full-name" && "Update Name"}
                </h3>
                <button
                  className="inline-flex items-center justify-center bg-transparent text-t-beige hover:text-t-skin"
                  onClick={closeModal}
                >
                  <PiXSquareLight
                    className="shrink-0"
                    size={32}
                  />
                </button>
              </div>
              {/* body */}
              <div className="space-y-2 p-2 md:space-y-4 md:p-4 xl:p-8">
                <div className="grid grid-cols-1 items-center gap-4 sm:grid-cols-4">
                  <label htmlFor="currentPassword">
                    {updateAttribute === "password" ? "Current " : ""}
                    Password
                  </label>
                  <input
                    className={`${activeTheme.input} w-full border border-black/5 bg-gray-50 p-2 focus:border-t-beige focus:ring-0 sm:col-span-3`}
                    id="currentPassword"
                    minLength={8}
                    name="current_password"
                    required
                    type="password"
                  />
                </div>
                <div className="grid grid-cols-1 items-center gap-4 sm:grid-cols-4">
                  <label htmlFor={updateAttribute}>
                    {updateAttribute === "email"
                      ? "New Email"
                      : updateAttribute === "password"
                        ? "New Password"
                        : "Your Name"}
                  </label>
                  <input
                    className={`${activeTheme.input} w-full border border-black/5 bg-gray-50 p-2 focus:border-t-beige focus:ring-0 sm:col-span-3`}
                    id={updateAttribute}
                    minLength={updateAttribute === "password" ? 8 : ""}
                    name={
                      updateAttribute === "email"
                        ? "email"
                        : updateAttribute === "password"
                          ? "password"
                          : "full_name"
                    }
                    placeholder={
                      updateAttribute === "email"
                        ? "example@gmail.com"
                        : updateAttribute === "password"
                          ? "••••••••"
                          : "Full Name"
                    }
                    type={
                      updateAttribute === "email"
                        ? "email"
                        : updateAttribute === "password"
                          ? "password"
                          : "text"
                    }
                    required
                  />
                </div>
                {updateAttribute === "password" && (
                  <div className="grid grid-cols-1 items-center gap-4 sm:grid-cols-4">
                    <label htmlFor="passwordConfirmation">
                      Confirm Password
                    </label>
                    <input
                      className={`${activeTheme.input} w-full border border-black/5 bg-gray-50 p-2 focus:border-t-beige focus:ring-0 sm:col-span-3`}
                      id="passwordConfirmation"
                      minLength={8}
                      name="password_confirmation"
                      placeholder="minimum of 8 characters"
                      required
                      type="password"
                    />
                  </div>
                )}
                <button
                  className={`${activeTheme.button.primary} sm:col-off s-text group flex w-full items-center justify-center gap-2 px-4 py-3 font-medium focus:ring-0`}
                  type="submit"
                >
                  Update
                  <PiArrowSquareRightLight
                    className="shrink-0"
                    size={20}
                  />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  const content = loading ? (
    <LoadingIndicator message="Loading profile" />
  ) : (
    <>
      {/* alert */}
      {alertVisible && (
        <Alert
          content={alertMessage}
          type={updateSuccessful ? "success" : "error"}
          visible={alertVisible}
        />
      )}
      {/* modal */}
      {modalVisible && form()}
      {/* main */}
      <ul className="flex flex-col justify-between gap-4 md:flex-row">
        <li className="flex flex-col gap-2">
          <span className="font-semibold">Name</span>
          {user.full_name}
          <span className="text-sm">(call to update)</span>
        </li>
        <li className="flex flex-col gap-2">
          <span className="font-semibold">Email</span>
          {user.email}
          <span className="text-sm">(call to update)</span>
        </li>
        <li className="flex flex-col gap-2">
          <span className="font-semibold">Password</span>
          <a
            className={`${activeTheme.link}`}
            href="#"
            onClick={() => openModal("password")}
          >
            Update
          </a>
        </li>
        {/* <li className="">
            <span className="font-semibold">Name</span>
            <a
              className={`${activeTheme.link}`}
              href="#"
              onClick={() => openModal("full-name")}
            >
              Update
            </a>
          </li>
          <li className="">
            <span className="font-semibold">Email</span>
            <a
              className={`${activeTheme.link}`}
              href="#"
              onClick={() => openModal("email")}
            >
              Update
            </a>
          </li> */}
      </ul>
    </>
  );

  return (
    <>
      <Header title="Profile" />
      <StandardContainer content={content} />
    </>
  );
}
