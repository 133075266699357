// packages
import _ from "lodash";
import { useEffect, useState } from "react";

// components
import Header from "../components/Header";
import StandardContainer from "../components/StandardContainer";
import LoadingIndicator from "../shared/components/LoadingIndicator";
import Pagination from "../shared/components/Pagination";
import Table from "../shared/components/Table";

// utils
import { fetchBookmarks } from "../shared/utils/bookmark";

export default function Bookmarks() {
  // data
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const fetchData = async () => {
    setLoading(true);

    try {
      const response = await fetchBookmarks(currentPage);

      setData(
        response.data.map((item) => {
          return {
            ...item,
            id: item.item.id,
            itemType: _.capitalize(item.item.type),
            date: new Date(item.created_at).toLocaleDateString(),
            path: item.item.type === "diamond" ? "/diamonds" : "/jewelry",
          };
        }),
      );

      // setTotalPages(response.meta.page_count);
    } catch (error) {
      console.error("Error fetching bookmarks:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  //////////////////////////////////////////////////////////////////////////////
  // UI
  //////////////////////////////////////////////////////////////////////////////

  const tableColumns = [
    { key: "item.item_no", label: "Item Number", link: true },
    { key: "itemType", label: "Type" },
    { key: "date", label: "Bookmarked On" },
  ];

  const content = loading ? (
    <LoadingIndicator message="Loading bookmarks" />
  ) : (
    <>
      <Pagination
        currentPage={currentPage - 1}
        onPageChange={handlePageChange}
        pageCount={totalPages}
      />
      <Table
        columns={tableColumns}
        data={data}
        showBookmarks={true}
      />
      <Pagination
        currentPage={currentPage - 1}
        onPageChange={handlePageChange}
        pageCount={totalPages}
      />
    </>
  );

  return (
    <>
      <Header title="Favorites" />
      <StandardContainer
        content={content}
        cssClasses="mt-2"
      />
    </>
  );
}
