const DEFAULT_THEME = {
  box: "border border-black/5 grid",
  button: "py-1 hover:bg-t-red hover:text-white border",
  buttonActive: "bg-t-gray text-white",
  buttonInactive: "",
  gridClass: "grid-cols-3",
  textClass: "text-sm",
};

export default function PickItems({
  callback,
  options,
  selectedValues,
  theme = {},
  title,
}) {
  const activeTheme = _.merge({}, DEFAULT_THEME, theme);

  return (
    <div className="space-y-2">
      <p className="text-sm">{title}</p>
      <div className={`${activeTheme.gridClass} ${activeTheme.box}`}>
        {Object.keys(options).map((key, index) => {
          const isActive = selectedValues.includes(options[key]);

          return (
            <button
              key={index}
              className={`${activeTheme.textClass} ${activeTheme.button} ${isActive ? activeTheme.buttonActive : activeTheme.buttonInactive}`}
              onClick={() => callback(options[key])}
            >
              {key}
            </button>
          );
        })}
      </div>
    </div>
  );
}
