// packages
import axios from "axios";
import _ from "lodash";
import { useEffect, useState } from "react";
import {
  MdOutlineForwardToInbox,
  MdOutlineRemoveShoppingCart,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";

// components
import Header from "../components/Header";
import StandardContainer from "../components/StandardContainer";
import Alert from "../shared/components/Alert";
import Table from "../shared/components/Table";

// utils
import {
  clearQuoteItems,
  findQuoteItem,
  loadQuoteItems,
  toggleQuoteItem,
} from "../shared/utils/quote";

export default function QuoteCart() {
  const navigate = useNavigate();

  // data
  const [data, setData] = useState([]);
  const [companyId, setCompanyId] = useState("");
  const [customerNotes, setCustomerNotes] = useState("");

  useEffect(() => {
    const profile = JSON.parse(localStorage.profile);
    setCompanyId(profile.company_id);

    // if (isCompanyUser(profile.role)) {
    //   navigate("/quotes");
    // }
  }, []);

  useEffect(() => {
    setData(handleQuoteItems());
  }, []);

  const handleQuoteItems = () => {
    return loadQuoteItems().map((item) => {
      return {
        ...item,
        type: _.capitalize(item.type),
        path: item.type === "diamond" ? "/diamonds" : "/jewelry",
      };
    });
  };

  const handleDelete = (e) => {
    const id = parseInt(
      e.currentTarget.parentNode.parentNode.getAttribute("data-id"),
      10,
    );

    const type = _.toLower(
      e.currentTarget.parentNode.parentNode.getAttribute("data-type"),
    );

    if (!id || !type) {
      return;
    }

    const item = findQuoteItem(type, id);

    if (!item) {
      return;
    }

    toggleQuoteItem(type, item);
    setData(handleQuoteItems());
  };

  const handleSubmit = () => {
    const itemIds = data.map((item) => {
      return item.type === "Diamond"
        ? { stone_id: item.id }
        : { jewelry_id: item.id };
    });

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/quotes`,
        {
          quote: {
            company_id: companyId,
            customer_notes: customerNotes,
            item_ids: itemIds,
          },
        },
        {
          headers: {
            Authorization: localStorage.token,
          },
        },
      )
      .then((_response) => {
        clearQuoteItems();
        navigate("/quotes");
      })
      .catch((error) => {
        console.error("Error submitting quote:", error);
      });
  };

  //////////////////////////////////////////////////////////////////////////////
  // UI
  //////////////////////////////////////////////////////////////////////////////

  const tableColumns = [
    { key: "data.item_no", label: "Item Number", link: true },
    { key: "type", label: "Type" },
    { key: "data.description", label: "Description" },
    { key: "price", label: "Price" },
    {
      classNames: "text-right",
      content: (
        <button
          onClick={handleDelete}
          title="Remove from quote"
        >
          <MdOutlineRemoveShoppingCart
            className="shrink-0 text-t-red"
            size="16"
          />
        </button>
      ),
      ignoreRowClick: true,
    },
  ];

  const content = (
    <>
      {data.length > 0 ? (
        <>
          <Alert
            content={
              "This quote has not been submitted yet. You can add or remove items and submit the request when ready."
            }
            type="warning"
            visible={true}
          />
          <Table
            data={data}
            columns={tableColumns}
          />
          <textarea
            className="w-full" // TODO: remove blue outline of focus
            name="customerNotes"
            onChange={(e) => setCustomerNotes(e.target.value)}
            placeholder="Additional notes..."
            rows={3}
            value={customerNotes}
          />
          <button
            className="flex w-full items-center justify-center bg-t-red px-2 py-2.5 text-sm font-medium text-white hover:bg-black"
            onClick={handleSubmit}
          >
            <MdOutlineForwardToInbox
              className="mr-1 shrink-0"
              size="20"
            />
            Request Quote
          </button>
        </>
      ) : (
        <Alert
          content={
            <span>
              No items have been added to this quote yet. Select some{" "}
              <a
                className="font-bold"
                href="/diamonds"
              >
                diamonds
              </a>{" "}
              or{" "}
              <a
                className="font-bold"
                href="/jewelry"
              >
                jewelry
              </a>{" "}
              items first.
            </span>
          }
          type="error"
          visible={true}
        />
      )}
    </>
  );

  return (
    <>
      <Header title="Quote Cart" />
      <StandardContainer content={content} />
    </>
  );
}
