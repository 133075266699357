// assets
import HeroImage from "../../assets/images/heroes/terms.avif";

export const meta = {
  title: "Terms of Service",
  description: "",
  keywords: [""],
};

export const hero = {
  image: {
    src: HeroImage,
    alt: "",
    width: 1000,
    height: 1000,
  },
  contents: [
    "The information provided on this website is for general informational purposes only and is subject to change at any time without prior notice. Ninacci makes no guarantees regarding the accuracy, completeness, or reliability of the information presented, and contents should not be construed as a legally binding commitment.",
    "While we make every effort to maintain the accuracy of the content on our site, we cannot be held legally liable for any errors, omissions, or the appropriateness and usefulness of the information provided.",
    "We reserve the right to modify or impose additional terms and conditions, including those related to the use of this website and the privacy of information submitted to or collected from the site, without prior notice.",
    "Links to third-party sites or off-site pages are provided for convenience only. We do not endorse or take responsibility for the content, accuracy, or reliability of external sources linked through this site.",
  ],
};
