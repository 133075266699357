// packages
// import { NavLink } from "react-router-dom";

export default function ItemGrid({ data, limit = false, showSummary = false }) {
  const selectedData = limit ? data && data.slice(0, limit) : data;

  return (
    <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 sm:gap-4 lg:grid-cols-4">
      {selectedData &&
        selectedData.map((item, idx) => {
          return (
            <a
              key={idx}
              className="border-hover group border border-black/5 p-4"
              href={item.route}
            >
              <h4 className="s-h4 mb-2 h-12 text-center">{item.title}</h4>
              {item.images && item.images.main && (
                <div className="flex justify-center overflow-hidden py-2">
                  <img
                    alt={item.images.main.alt}
                    className="max-h-36 object-cover transition-all duration-300 group-hover:scale-110"
                    loading="lazy"
                    src={item.images.main.src}
                  />
                </div>
              )}
              <div
                className={`${showSummary ? "justify-between" : "justify-center"} mt-2 flex items-center gap-2`}
              >
                {showSummary && (
                  <p className="font-body text-sm">{item.summary}</p>
                )}
                <p className="font-body text-lg font-bold">{item.price}</p>
              </div>
            </a>
          );
        })}
    </div>
  );
}
