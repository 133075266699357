// packages
import { useRef } from "react";
import {
  PiLinkLight,
  PiMagnifyingGlassLight,
  PiPlayLight,
  PiSketchLogoLight,
} from "react-icons/pi";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

export default function ItemGallery({ data }) {
  const imageRef = useRef();
  const handleZoom = () => {
    if (imageRef.current) {
      imageRef.current.click();
    }
  };

  const imageGallery = (
    <div className="zz-h-full zz-lg:contain-size flex flex-1 items-start justify-center border border-black/5 p-4 sm:p-8">
      <Zoom>
        <img
          className="size-full object-contain"
          src={data.images.main.src}
          alt={data.images.main.alt}
          loading="eager"
          ref={imageRef}
        />
      </Zoom>
    </div>
  );

  const controlPanel = (
    <div className="absolute right-0 flex divide-x border border-black/5">
      <button
        className="group flex items-center justify-center overflow-hidden bg-white p-2 text-t-gray hover:border-gray-200"
        onClick={handleZoom}
        title="Zoom image"
      >
        <PiMagnifyingGlassLight className="size-6 object-cover group-hover:scale-110" />
      </button>
      {data.videoUrl && (
        <a
          className="group flex items-center justify-center overflow-hidden bg-white p-2 text-t-gray hover:border-gray-200"
          href={data.videoUrl}
          target="_blank"
          title="View video (new window)"
        >
          <PiPlayLight className="group-hover:scale-11 size-6 object-cover" />
        </a>
      )}
      {data.type === "diamond" && data.certificateUrl && (
        <a
          className="group flex items-center justify-center overflow-hidden bg-white p-2 text-t-gray hover:border-gray-200"
          href={data.certificateUrl}
          target="_blank"
          title="View certificate (new window)"
        >
          <PiSketchLogoLight className="size-6 object-cover group-hover:scale-110" />
        </a>
      )}
      {data.webUrl && (
        <a
          className="group flex items-center justify-center overflow-hidden bg-white p-2 text-t-gray hover:border-gray-200"
          href={data.webUrl}
          target="_blank"
          title="View link (new window)"
        >
          <PiLinkLight className="size-6 object-cover group-hover:scale-110" />
        </a>
      )}
    </div>
  );

  return (
    <div className="zz-h-full relative flex flex-col gap-8">
      {imageGallery}
      {controlPanel}
    </div>
  );
}
