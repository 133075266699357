export default function OrderDetails({ data }) {
  // TODO: this is temporary
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 5);
  const deliveryDate = currentDate.toLocaleString("en-US", {
    weekday: "short",
    month: "short",
    day: "numeric",
  });

  return (
    <div className="s-text bg-t-gray p-2 text-t-skin md:p-4 lg:p-12">
      <h3 className="s-h3 mb-1">All Order Include</h3>
      <ul className="s-list font-normal">
        <li>Lab Certificate</li>
        <li>Professional Appraisal</li>
        <li>Free Lifetime Warranty</li>
      </ul>
      <h3 className="s-h3 mb-1 mt-4">Flexible Payment Options</h3>
      <ul className="s-list font-normal">
        <li>Special 12-month, 0% APR financing available</li>
        <li>Easy 36 monthly payments available, subject to approval</li>
        <li>Layaway options also available</li>
      </ul>
      <h3 className="s-h3 mb-1 mt-4">Estimated Delivery by {deliveryDate}</h3>
      <ul className="s-list font-normal">
        <li>Estimated delivery if ordered by 2PM PST today</li>
        <li>Free worldwide FedEx shipping</li>
        <li>Free 30 day returns</li>
        <li>100% insured</li>
      </ul>
    </div>
  );
}
