// packages
import { MdInfoOutline, MdOutlineClose } from "react-icons/md";

// TODO: fix issue with modal not closing when clicking outside or pressing Esc
//  as currently implemented, it acts as a static modal instead of dynamic
export default function Modal({ isOpen, onClose, data, settings }) {
  const header = settings.columns.find((column) => column.header === true);
  const columns = settings.columns.filter((column) => column.header !== true);

  // https://flowbite.com/docs/components/modal/#default-modal
  return (
    isOpen && (
      <div
        aria-hidden="true"
        className="fixed left-0 right-0 top-0 z-50 h-[calc(100%-1rem)] max-h-full w-full overflow-y-auto overflow-x-hidden bg-black/50 p-4 md:inset-0"
        id="defaultModal"
        tabIndex="-1"
      >
        <div className="relative flex h-full max-h-full w-full items-center justify-center">
          <div className="relative w-full rounded-md bg-white shadow lg:w-1/2">
            {/* header */}
            <div className="flex items-center justify-between rounded-t border-b bg-theme-blue p-4 text-white">
              {header && (
                <h3 className="text-xl font-semibold">{data[header.key]}</h3>
              )}
              <button
                className="inline-flex h-8 w-8 items-center justify-center rounded-md bg-transparent hover:bg-white/10"
                onClick={onClose}
                type="button"
              >
                <MdOutlineClose
                  size={24}
                  className="shrink-0"
                />
              </button>
            </div>
            {/* body */}
            <div className="px-4 pb-2 pt-4">
              <ul className="w-full space-y-2">
                {columns.map((column) => (
                  <li
                    key={column.key}
                    className="flex w-full justify-between border-b border-gray-200 pb-2"
                  >
                    <div className="font-semibold">{column.label}</div>
                    <div>{data[column.key]}</div>
                  </li>
                ))}
              </ul>
            </div>
            {/* footer */}
            <div className="flex items-center justify-end space-x-2 border-gray-200 px-4 pb-4 pt-2">
              <a
                className="group flex items-center rounded-md bg-theme-blue px-3 py-2 text-white hover:bg-black"
                href={`/${settings.detailsLinkPath}/${data.id}`}
              >
                <MdInfoOutline
                  className="mr-1 shrink-0 group-hover:scale-[1.2]"
                  size="16"
                />
                Details
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
