// packages
import React from "react";

// components (template)
import StandardContainer from "./StandardContainer";

export default function Hero({
  title,
  content,
  image,
  flip = false,
  showBorders = true,
}) {
  const contentItem = (item, idx) => {
    if (typeof item === "string") {
      const tagMatch = item.match(/^<(\w+)>(.*?)<\/\1>$/s);

      if (!tagMatch) {
        return (
          <p
            key={idx}
            className="s-text text-center"
            dangerouslySetInnerHTML={{ __html: item }}
          />
        );
      }

      const tagName = tagMatch[1];
      const innerHTML = tagMatch[2];
      const tagClassName = `${tagName === "h2" ? "s-h2" : ""} text-center`;

      return React.createElement(tagName, {
        key: idx,
        className: tagClassName,
        dangerouslySetInnerHTML: { __html: innerHTML },
      });
    } else if (Array.isArray(item)) {
      return (
        <ul
          key={idx}
          className="s-list s-text"
        >
          {item.map((listItem, idx2) => (
            <li
              key={idx2}
              dangerouslySetInnerHTML={{ __html: listItem }}
            />
          ))}
        </ul>
      );
    } else if (item.type === "component") {
      const Component = item.component;
      return (
        <Component
          {...item.props}
          key={idx}
        />
      );
    }
  };

  const titleSection = title && (
    <h1
      className={`${showBorders && "border-b border-black/5"} s-h1 pb-4 text-center sm:pb-6`}
    >
      {title}
    </h1>
  );

  const imageSection = image && (
    <img
      className="sticky top-0 object-cover object-center"
      src={image.src}
      alt={image.alt}
      width={image.width}
      height={image.height}
    />
  );

  const contentSection = content && (
    <div className="mx-auto mb-4 flex max-w-screen-lg flex-col gap-6 self-center sm:mb-6">
      {content.map((item, idx) => contentItem(item, idx))}
      {/* {content.map((paragraph, idx) => (
        <p
          key={idx}
          className="text-center s-text"
          dangerouslySetInnerHTML={{ __html: paragraph }}
        />
        //   {paragraph}
        // </p>
      ))} */}
    </div>
  );

  return (
    <StandardContainer
      content={
        <div className="my-4 flex flex-col gap-4 sm:my-8 sm:gap-6">
          {titleSection}
          {(image || content) && (
            <div
              className={`${image && content && "md:grid-cols-2"} ${showBorders && "border-b border-black/5"} grid grid-cols-1 gap-4 sm:gap-8`}
            >
              {flip ? (
                <>
                  {contentSection}
                  {imageSection}
                </>
              ) : (
                <>
                  {imageSection}
                  {contentSection}
                </>
              )}
            </div>
          )}
        </div>
      }
    />
  );
}
