// packages
import axios from "axios";
import { useEffect, useState } from "react";

// classes
import Diamond from "../../shared/classes/Diamond";
import Jewelry from "../../shared/classes/Jewelry";

// components (shared)
import ItemGrid from "../../shared/components/ItemGrid";

// TODO: replace with similar items when implemented instead of featured items
export default function SimilarItems({ type, id }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/companies/${process.env.REACT_APP_COMPANY_ID}/${type === "diamond" ? "stone" : "jewelry"}-similar/${id}.json`,
      )
      .then((response) => {
        if (type === "diamond") {
          setData(
            response.data.diamonds.map((item) => {
              return new Diamond(item, "/diamonds");
            }),
          );
        } else if (type === "jewelry") {
          setData(
            response.data.jewelry.map((item) => {
              return new Jewelry(item, "/jewelry");
            }),
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching similar items:", error);
      });
  }, []);

  return (
    data &&
    data.length > 0 && (
      <div>
        <h3 className="s-h3 my-4 border-b border-black/5 pb-4 text-center uppercase">
          Similar Items
        </h3>
        <ItemGrid
          data={data}
          limit={4}
        />
      </div>
    )
  );
}
